<template>
    <div class="pay-result-box">
        <nav-index :title="'支付结果'"/>
        <div class="card">
            <div class="result">
                <img src="../../assets/images/pay-success.png" alt="" width="50" height="50">
                <div>付款成功</div>
            </div>
            <div class="row" v-if="$route.query.memberId || $route.query.partnerId">
                <div class="button" @click="goPersonal">返回主页</div>
                <div class="button" @click="goOrder">查看订单</div>
            </div>
            <div class="row" v-else>
                <div class="button" @click="goShop">继续浏览</div>
                <div class="button" @click="goOrder">查看订单</div>
            </div>
        </div>
    </div>
</template>

<script>
import NavIndex from '../../components/nav/index.vue'
export default {
    name: 'Booking',
    components: {
      NavIndex
    },
    created(){

    },
    mounted(){

    },
    data:()=>{
      return {

      }
    },
    methods:{
        goShop(){
            this.$router.push('/shop')
        },
        goOrder(){
            this.$router.push('/order')
        },
        goPersonal(){
            this.$router.push('/personal')
        }
    }
}
</script>

<style lang="scss" scoped>
.pay-result-box {
    height: calc(100vh - 50px);
    padding-top: 50px;
    .card {
        padding: 45px 15px 15px;
        background: #fff;
        .result {
            img {
                padding-bottom: 15px;
            }
            div {
                font-size: 18px;
                font-weight: 600;
                color: rgba(0,0,0,0.7);
            }
        }
        .row {
            display: flex;
            justify-content: space-between;
            padding: 0 40px;
            margin: 10px 0;
            .button {
                margin: 10px 0;
                padding: 10px 25px;
                border: 1px solid #ddd;
                border-radius: 30px;
            }
        }
    }
}
</style>